.bannerList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bannerList .FormBox {
  flex: 1;
}
.createdCourse .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 480px;
  font-size: 14px;
}
.createdCourse .level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: center;
}
.btn-box button {
  padding: 12px 30px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
